import React, {useContext} from 'react';
import LabMonStartForm from 'components/labmon/StartForm';
import AuthCardLayout from 'layouts/AuthCardLayout';
import { useLocation } from 'react-router-dom';
import DeviceContents from '../DeviceContents';

// Import the authorization context module 
import { AuthContext } from 'context/auth-context';
import LockScreen from 'components/authentication/card/LockScreen';


const LabMonStart = () => {

  // Define the context variable and listen to context changes 
  const authorizationContext = useContext(AuthContext);

  // Extract the IP from the URL parameters 
  const {state: {ip, locked_user}} = useLocation();

  // If not logged in or timed out, display the lock screen 
  if (authorizationContext.isLoggedIn === false) {

    // Return the lock screen 
    return (<LockScreen firstName={authorizationContext.firstName} lastName={authorizationContext.lastName}/>);

  } 

  // https://fontawesome.com/v5/docs/web/style/lists
  return (
    <AuthCardLayout leftSideContent={<DeviceContents ip={ip} />} >
      <h3>Test Configuration & Start</h3>
      <LabMonStartForm layout="card" ip={ip} locked_user={locked_user} hasLabel />
    </AuthCardLayout>
  );
  
};

export default LabMonStart;
