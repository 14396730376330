import React, { useState, useEffect, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Col, Form, Row } from 'react-bootstrap';
import classNames from 'classnames';

// Import the default.json file from the config folder
import config from 'config/default.json';

// Import the validators
import { validate, VALIDATOR_EMAIL, VALIDATOR_MINLENGTH } from 'utils/validators';

// Import the authorization context module 
import { AuthContext } from 'context/auth-context';


// Lock screen (form) and handler 
const LockScreenForm = ({ type, ...rest }) => {

  // Define the context variable and listen to context changes 
  const authorizationContext = useContext(AuthContext);

  // Format data entered by the user and used for the login operation
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  
  // Form reducer is invoked when 'dispatch()' function is called.
  // In the form reducer function, we will validate the input and also check whether the form is valid.
  const formReducer = (state, action) => {

    // Debug message 
    console.log(action.type, action.value);

    // Check whether the user email is valid 
    if (action.type === 'email') {
      state.inputs.userEmail.value = action.value.trim();
      state.inputs.userEmail.isAttempted = true;
      state.inputs.userEmail.isValid = validate(action.value.trim(), [VALIDATOR_EMAIL()]);
    }

    // Check whether the password is valid 
    if (action.type === 'password') {
      state.inputs.password.value = action.value.trim();
      state.inputs.password.isAttempted = true;
      state.inputs.password.isValid = validate(action.value.trim(), [VALIDATOR_MINLENGTH(8)]);
    }
    
    // Debug message 
    console.log(state.inputs.userEmail.isValid, state.inputs.password.isValid, state.isFormValid);

    // Set the form is Valid flag 
    return {
      inputs: {
        ...state.inputs,
      },
      isFormValid: (state.inputs.userEmail.isValid && state.inputs.password.isValid)
    }

  }

  // Define the form state handler
  const [formState, dispatch] = useReducer(formReducer, {

    // Inputs to be monitored for the form state validated. 
    // Note: All the inputs should be valid for the form to become valid.
    inputs: {

      // User Email
      userEmail: {
        value: formData.email,
        isAttempted: false,
        isValid: false
      },

      // Password
      password: {
        value: formData.password,
        isAttempted: false,
        isValid: false
      },

    },

    // Initial state of the form. Assume it is invalid.
    isFormValid: false

  } /*, initInitialState */);
  

  // Dispatch the email (because the authorization context is already valid)
  useEffect(() => {
    dispatch({type: 'email', value: authorizationContext.emailAddress});
    setFormData({...formData, email: authorizationContext.emailAddress});
  }, [authorizationContext])
  

  // Function to handler form submission
  const onSubmitHandler = async (event) => {

    // Prevent the default handler 
    event.preventDefault();

    // Format the back end URL
    let backendURL = `${config.authServer.http_prefix}${config.authServer.hostname}:${config.authServer.port}/login`;

    try {

      // Variable to hold the response 
      let response = null;

      // Create the POST request for login
      response = await fetch(backendURL , {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({userEmail: formData.email, password: formData.password})
      });

      // Display the response data 
      console.log(response);

      // Check whether the operation was successful 
      if (response.status === 200) {

        // Update the submit response data 
        const responseData = await response.json();

        // Display the response data 
        console.log(responseData);

        // Clear the login error message
        toast.success(`Logged in as '${responseData.firstName} ${responseData.lastName}'`)

        // Call the authentication context login function 
        // Note: We are passing 'null' for the expiry time. This will be computed in the App.js file.
        authorizationContext.login(
          responseData.firstName, responseData.lastName, responseData.emailAddress, 
          responseData.jwtToken, responseData.permissions, null);

      } else {

        // Set the login error message
        toast.error('Invalid Email / Password. Please check and re-enter your password.');

      }

    } catch (error) {

      // Debug message 
      console.log(error);
      toast.error('Error During Login. Try Again / Contact Administrator.')

    }
    
  }

  return (
    <Row
      {...rest}
      as={Form}
      className={classNames('gx-2 mt-4', {
        'mx-sm-4 mb-2': type === 'simple'
      })}
      onSubmit={onSubmitHandler}
    >
      <Col>
        <Form.Control
          placeholder="Enter your password"
          value={formData.password}
          name="password"
          onChange={(event) => {
            dispatch({type: 'password', value: event.currentTarget.value})
            setFormData({...formData, password: event.currentTarget.value})
          }}
          type="password"
        />
      </Col>
      <Col xs={type === 'simple' ? 'auto' : 4}>
        <Button
          variant="primary"
          type="submit"
          disabled={!formState.isFormValid}
          className={classNames({ 'w-100': type !== 'simple' })}
        >
          Login
        </Button>
      </Col>
    </Row>
  );
};

LockScreenForm.propTypes = {
  type: PropTypes.oneOf(['simple', 'split', 'card'])
};

export default LockScreenForm;
