import React, { useState } from 'react';
import { CloseButton, Modal } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import RegistrationForm from 'components/authentication/RegistrationForm';
import { Table } from 'react-bootstrap';
import ActionButton from 'components/common/ActionButton';
import Notification from 'components/notification/Notification';

const LabMonDashboard = () => {
  let {
    state: { open }
  } = useLocation();
  console.log(useLocation())
  const navigate = useNavigate();

  const [show, setShow] = useState(open);

  const handleClose = () => {
    setShow(false);
    navigate(-1);
  };

  return (
    <Modal show={show} onHide={handleClose} className="mt-4">
      <Modal.Header className="bg-shape modal-shape-header px-4 position-relative">
        <div className="position-relative z-index-1 light">
          <h4 className="mb-0 text-white" id="authentication-modal-label">
            RaspberryPi
          </h4>
          <p className="fs--1 mb-0 text-white">
            Model 4B, 8GB RAM
          </p>
        </div>
        <CloseButton
          variant="white"
          className="position-absolute end-0 me-2 mt-2 top-0"
          onClick={handleClose}
        />
      </Modal.Header>
      <Modal.Body className="p-4">
        {/* <RegistrationForm layout="split" hasLabel /> */}
        <div style={{maxWidth:'30rem'}}>
          <Notification
            avatar={{name: 'Power Supply', size: 'xl', round: 'circle'}}
            children='<strong>Power Supply</strong> Tenma 72-2940, 60V/5A'
            time=""
            unread={true}
          />
          <Notification
            avatar={{name: 'Electronic Load', size: 'xl', round: 'circle'}}
            children='<strong>Electronic Load</strong> TENMA 72-13210, 300 W, 0-120V/30A'
            time=""
          />
          <Notification
            avatar={{name: 'Battery Monitor', size: 'xl', round: 'circle'}}
            children='<strong>Battery Monitor</strong> Version 1, 6.4A'
            time=""
            unread={true}
          />
          {/* <ActionButton icon="edit" title="Edit" variant="action" className="p-0 me-2" /> */}
        </div>

        {/*
        <Table bordered >
          <colgroup>
            <col className="bg-soft-primary" />
            <col />
            <col />
            <col />
          </colgroup>
          <thead>
            <tr>
              <th scope="col">Equipment</th>
              <th scope="col">Model</th>
              <th scope="col">Type / Version</th>
              <th className="text-end" scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Power Supply</td>
              <td>Tenma</td>
              <td>72-2940, 60V/5A</td>
              <td className="text-end">
                <ActionButton icon="edit" title="Edit" variant="action" className="p-0 me-2" />
                <ActionButton icon="trash-alt" title="Delete" variant="action" className="p-0" />
              </td>
            </tr>

          </tbody>
        </Table>
        */}
      </Modal.Body>
    </Modal>
  );
}

export default LabMonDashboard;
