export const todoList = [
  {
    id: 1,
    task: 'Sidenav text cutoff rendering issue',
    title: 'Problem with Falcon theme',
    completed: false
  },
  {
    id: 2,
    task: 'Notify when the WebPack release is ready',
    title: 'Falcon Bootstarp 5',
    completed: false
  },
  {
    id: 3,
    task: 'File Attachments',
    title:
      'Sending attachments automatically attaches them to the notification email that the client receives as well as making them accessible through.',
    completed: false
  },
  {
    id: 4,
    task: 'Billing a Client',
    title:
      "It's also possible to create a billable item for a client when you reply to a ticket. You can generate invoices or log time spent on a ticket.",
    completed: false
  },
  {
    id: 5,
    task: 'Sidenav text cutoff rendering issue',
    title: 'Problem with Falcon theme',
    completed: false
  },
  {
    id: 6,
    task: 'Notify when the WebPack release is ready',
    title: 'Falcon Bootstarp 5',
    completed: false
  },
  {
    id: 7,
    task: 'File Attachments',
    title:
      'Sending attachments automatically attaches them to the notification email that the client receives as well as making them accessible through.',
    completed: false
  }
];
