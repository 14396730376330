import React from 'react';
import Avatar from 'components/common/Avatar';
import LockScreenForm from 'components/authentication/LockScreenForm';
import AuthCardLayout from 'layouts/AuthCardLayout';
import Flex from 'components/common/Flex';
import { Row, Col } from 'react-bootstrap';

// Import the avatar image 
import sadashivan from 'assets/img/team/Sadashivan-thumb.jpg';


// Lock screen control
const LockScreen = ({firstName, lastName}) => {

  // Variable to hold the avatar image source 
  // const avatarImg = sadashivan;
  const avatarImg = null;

  // Return the lock screen control
  return (
    <AuthCardLayout>
      <Row className="justify-content-center">
        <Col xs="auto">
          <Flex alignItems="center">
            {(!avatarImg) && (<Avatar name={`${firstName} ${lastName}`} size="4xl" className="me-4" />)}
            {(avatarImg) && (<Avatar src={avatarImg} size="4xl" className="me-4" />)}
            <div>
              <h4>{`Hi! ${firstName} ${lastName}`}</h4>
              <p className="mb-0">
                Screen Locked due to Inactivity.<br />
                Enter your password to access the dashboard.
              </p>
            </div>
          </Flex>
          <LockScreenForm className="mt-4" />
        </Col>
      </Row>
    </AuthCardLayout>
  );
};

export default LockScreen;
