import React from 'react';
import { Col, Row } from 'react-bootstrap';
import CrmStats from './CrmStats';
import Revenue from './revenue/Revenue';
import Greetings from './Greetings';

import {
  recentActivities,
} from 'data/dashboard/recentActivities';
import RecentActivity from './RecentActivity';

const Crm = () => {
  return (
    <>
      <Greetings />
      <Row className="g-3 mb-3">
        <Col xxl={9}>
          <CrmStats />
          <Revenue />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col xxl={9}>
          <RecentActivity data={recentActivities}/>
        </Col>
      </Row>
    </>
  );
};

export default Crm;
