import React, {useState, useEffect, useContext, } from 'react';
import { Col, Row } from 'react-bootstrap';
import { setupInfo, statusBadges, priorityBadges } from 'data/dashboard/setupInfo';
import Greetings from './Greetings';
import UnsolvedTickets from './unsolved-tickets/UnsolvedTickets';
import { toast } from 'react-toastify';
import LockScreen from 'components/authentication/card/LockScreen';

// Import the default.json file from the config folder
import config from 'config/default.json';

// Load the models JSON file 
import models from 'config/models.json'

// Import the authorization context module 
import { AuthContext } from 'context/auth-context';

// Import the avatar imges 
import sadashivan from 'assets/img/team/Sadashivan-thumb.jpg';
import labmon from 'assets/img/team/Labmon-thumb.png';


const SupportDesk = () => {

  // Variable to specify whether the node list was queries on load
  const [initialised, setInitialised] = useState(false);

  // Variable to hold the list of nodes that are active 
  const [nodes, setNodes] = useState([]);

  // Variable to hold the setup status 
  const [setupStatus, setSetupStatus] = useState(setupInfo);
    /*
    {
      id: 100,
      name: 'Sadashivan M',
      avatar: {
        img: sadashivan,
        size: 'xl'
      },
      setupip: '192.168.1.113',
      subject: '12V2A: 3Cell, 2600mAh, OCT2023 Manufacturing (Golden Sample)',
      status: { content: 'Completed Successfully', type: 'success' },
      priority: {
        title: 'Urgent',
        color: '#e63757',
        data: 100
      },
      agent: 'Anindya',
      date: '2d ago'
    }
    */


  // Define the context variable and listen to context changes 
  const authorizationContext = useContext(AuthContext);


  // Call the function to get the node list 
  useEffect(() => {

    // Function to get the node list
    const getNodeList = async () => {

      // Format the back end URL
      const backendURL = 
        `${config.reliabilityServer.http_prefix}${config.reliabilityServer.hostname}:${config.reliabilityServer.port}/` +
        `labmon/nodes` ;

      try {

        // Create the POST request for quering the list of nodes
        const response = await fetch(backendURL , {
          method: 'GET',
          headers: {'Content-Type': 'application/json'}
        });

        // Check whether the request for list of nodes is successful
        if (response.status === 200) {

          // Parse the JSON response 
          const responseData = await response.json();

          // Display the node list
          console.log(responseData.nodeList);

          // Set the nodes 
          setNodes(responseData.nodeList);
          
        } else {

          // Error during request node list operation
          toast.error('Error durng the request node list operation');

        }

      } catch (error) {

        // Debug message 
        console.log(error);
        toast.error('Error durng the request node list operation');

      }
      
    }

    // Initialise the node list 
    if (initialised === false) {
      getNodeList();
    }
    
    // Update the initialised flag 
    setInitialised(true);

  }, [initialised])

  // Format the setup status 
  useEffect(() => {

    // Format the setup status list
    nodes.map((node, index) => {

      // Debug message 
      console.log(node);

      // Scan through the setups and update the status
      setupStatus.map((setup, index) => {

        if (setup.setupip === node.address) {
          console.log(`Updating details for ${setup.setupip}`)
          setupStatus[index].subject = 'Unknown';
          if (node.status && node.status.model && node.status.variant) {
            setupStatus[index].subject = `${node.status.model}, ${node.status.variant}`
          }
          if (node.status && node.status.running === true) {
            setupStatus[index].status = statusBadges[3]
          }
          if (node.status && node.status.running !== undefined && node.status.running === false) {
            setupStatus[index].status = statusBadges[0]
          }
          if (node.status && node.status.requested_by && node.status.requested_by.first_name && node.status.requested_by.last_name) {
            setupStatus[index].name = `${node.status.requested_by.first_name} ${node.status.requested_by.last_name}`
            setupStatus[index].avatar.email = node.status.requested_by.email
            if (node.status.requested_by.first_name === "Sadashivan") {
              setupStatus[index].avatar.img = sadashivan
            } else {
              setupStatus[index].avatar.name = `${node.status.requested_by.first_name} ${node.status.requested_by.last_name}`
              setupStatus[index].avatar.round = 'circle'
            }
          } else {
            setupStatus[index].name = 'LabMon'
            setupStatus[index].avatar.email = null
            setupStatus[index].avatar.img = labmon
          }
        }

        return true; // map() expects a return

      })

      return true; // map() expects a return 

    })

  }, [nodes, setupStatus]);

  // If not logged in or timed out, display the lock screen 
  if (authorizationContext.isLoggedIn === false) {

    // Return the lock screen 
    return (<LockScreen firstName={authorizationContext.firstName} lastName={authorizationContext.lastName}/>);

  } 

  // Return the dashboard
  return (
    <>
      <Row className="g-3 mb-3">
        <Col xxl={6}>
          <Row className="g-0 h-100">
            <Col xs={12} className="mb-3">
              <Greetings />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="g-3">
        <Col xxl={9}>
          <UnsolvedTickets data={setupStatus} />
        </Col>
      </Row>
    </>
  );
};

export default SupportDesk;
