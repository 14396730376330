import PropTypes from 'prop-types';
import React, {useState, useReducer, useContext, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

// Import the ranger componet 
import FalconReactRange from 'components/doc-components/FalconReactRange';

// Import the default.json file from the config folder
import config from 'config/default.json';

// Load the models JSON file 
import models from 'config/models.json'

// Import the validators
import { validate, VALIDATOR_MINLENGTH, VALIDATOR_MAXLENGTH } from 'utils/validators';

// Import the authorization context module 
import { AuthContext } from 'context/auth-context';


// Labmon configuration and start main function
const LabMonStartForm = ({ layout, ip, locked_user, hasLabel }) => {

  // Variable to hold the model selected 
  const [selectedModel, setSelectedModel] = useState('');

  // Variable to hold the variants for the selected model 
  const [modelVariants, setModelVariants] = useState([]);

  // Variable to hold the variant selected 
  const [selectedVariant, setSelectedVariant] = useState('');

  // Variable to hold the test case selected 
  const [selectedTestCase, setSelectedTestCase] = useState('');

  // Variable to hold the sample qualification range
  const [sampleQualificationRange, setSampleQualificationRange] = useState([1, 20]);


  // On submit the following sequence of events will happen:
  //
  //   a) Initiate MQTT Connect         - Use State (Connectioin Initiated)
  //   b) Initiate MQTT Get Status      - Use State (Status)
  //   c) Status OK, Initiate Config    - Use State (MQTT Config)
  //   d) Config OK, Initiate Start     - Use State (MQTT Start)
  const [connected, setConnected] = useState(false);
  const [status, setStatus] = useState('Unknown');
  const [configured, setConfigured] = useState(false);
  const [started, setStarted] = useState(false);

  // Format data entered by the user and used for the login operation
  const [formData, setFormData] = useState({
    description: '',
    serialNumber: '',
    model: '',
    variant: '',
    testCase: ''
  });


  // Define the context variable and listen to context changes 
  const authorizationContext = useContext(AuthContext);

  // Display the IP address 
  console.log(`Inside LabMonStartForm. IP ${ip}, Connected ${connected}, Status ${status}`);
  console.log(`Setup currently used / locked by ${locked_user}`)

  // Call the function to connect to the device 
  useEffect(() => {

    // Establish a connection with the device 
    const connect = async () => {

      // Format the back end URL
      const backendURL = 
        `${config.reliabilityServer.http_prefix}${config.reliabilityServer.hostname}:${config.reliabilityServer.port}/` +
        `labmon/${ip}/connect` ;

      try {

        // Create the POST request for connect request
        const response = await fetch(backendURL , {
          method: 'POST',
          headers: {'Content-Type': 'application/json'}
        });

        // Display the response data 
        console.log(response);

        // Check whether the operation was successful 
        if (response.status === 200) {

          // Successfully connected to labmon
          toast.success(`Successfully Connected to '${ip}'`)

          // Set the connected flag 
          setConnected(true);

        } else {

          // Error during connect operation
          toast.error(`Error Connecting to '${ip}'`);

          // Reset the connected flag 
          setConnected(false);

        }

      } catch (error) {

        // Debug message 
        console.log(error);
        toast.error(`Error During Connect Operation '${ip}'`)

        // Reset the connected flag 
        setConnected(false);

      }
      
      // Set labmon configured to false 
      setConfigured(false);

      // Set labmon started to false 
      setStarted(false);

    }

    // Call the connect function 
    connect();

  }, [ip]);
  

  // If the connection is successful, get the 'labmon' operation status 
  useEffect(() => {
    
    // Function to handle device change notifications 
    const deviceChangeNotification = () => {

      // Connecting to the SSE based on the ip selected
      const eventSource = new EventSource(
        `${config.reliabilityServer.http_prefix}${config.reliabilityServer.hostname}:${config.reliabilityServer.port}/sse/${ip}/events`);

      // SSE for to recieve message/data from raspberry pi
      eventSource.onmessage = (e) => {
      
        // If this is not JSON return 
        try {

          // Parse the data 
          const data = JSON.parse(e.data);

          // Debug message 
          console.log(data);

          // If this is status message, update the status 
          if (data.status !== undefined) {
            setStatus(data.status);
          }
        
        } catch {

          // Just return 
          return;

        }

      };

      return () => {
          eventSource.close();
      }
      
    }

    // If the connection is successful, query the 'labmon' operation status
    if (connected === true) {

      // Register for device change notifications 
      deviceChangeNotification();

      // Query the 'labmon' operation status
      const getOperationStatus = async () => {

        // Format the back end URL
        const backendURL = 
          `${config.reliabilityServer.http_prefix}${config.reliabilityServer.hostname}:${config.reliabilityServer.port}/` +
          `labmon/${ip}/operation` ;

        try {

          // Create the POST request for labmon operation status
          const response = await fetch(backendURL , {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({operation: "get_status"})
          });

          // Display the response data 
          console.log(response);

          // Check whether the operation was successful 
          if (response.status !== 202) {

            // Error during labmon operation status query operation
            toast.error(`Labmon Status Error for '${ip}'`)

            // Reset the status 
            setStatus('');

          } else {

            // Display the status 
            const responseData = await response.json();
            console.log(responseData);

          }

        } catch (error) {

          // Debug message 
          console.log(error);
          toast.error(`Error During LabMon Status Operation '${ip}'`)

          // Reset the status 
          setStatus('');

        }
        
      }

      // Call the function to query the lambon operation status
      getOperationStatus();
      
    }

  }, [connected, ip]);

  // If the status has changed, obtain the detailed statu s
  useEffect(() => {

    // If the status is 'running', then get the detailed status 
    if (status === 'running') {

    }

  }, [status])

  // Function to select the variants when the model changes 
  useEffect(() => {

    // Look up the models and update the variant 
    if (selectedModel !== '') {
      let index = 0;
      for (index = 0; index < models.length; index++) {
        if (models[index].Model === selectedModel) {
          setModelVariants(models[index].Variants);
          break;
        }
      }
    }

  }, [selectedModel])

  // Form reducer is invoked when 'dispatch()' function is called.
  // In the form reducer function, we will validate the input and also check whether the form is valid.
  const formReducer = (state, action) => {

    // Check whether the serial number is valid 
    if (action.type === 'description') {
      state.inputs.description.value = action.value.trim();
      state.inputs.description.isAttempted = true;
      state.inputs.description.isValid = validate(action.value.trim(), [VALIDATOR_MINLENGTH(20), VALIDATOR_MAXLENGTH(40)]);
    }

    // Check whether the serial number is valid 
    if (action.type === 'serialNumber') {
      state.inputs.serialNumber.value = action.value.trim();
      state.inputs.serialNumber.isAttempted = true;
      state.inputs.serialNumber.isValid = validate(action.value.trim(), [VALIDATOR_MINLENGTH(10), VALIDATOR_MAXLENGTH(10)]);
    }

    // Check whether the model is valid 
    if (action.type === 'model') {
      state.inputs.model.value = action.value.trim();
      state.inputs.model.isAttempted = true;
      state.inputs.model.isValid = validate(action.value.trim(), [VALIDATOR_MINLENGTH(4)]);
    }
    
    // Check whether the variant is valid 
    if (action.type === 'variant') {
      state.inputs.variant.value = action.value.trim();
      state.inputs.variant.isAttempted = true;
      state.inputs.variant.isValid = validate(action.value.trim(), [VALIDATOR_MINLENGTH(5)]);
    }
    
    // Check whether the test case is valid 
    if (action.type === 'testCase') {
      state.inputs.testCase.value = action.value.trim();
      state.inputs.testCase.isAttempted = true;
      state.inputs.testCase.isValid = validate(action.value.trim(), [VALIDATOR_MINLENGTH(5)]);
    }
    
    // Debug message 
    // console.log(state.inputs.description.isValid, state.inputs.serialNumber.isValid,
    //   state.inputs.model.isValid, state.inputs.variant.isValid, state.inputs.testCase.isValid,
    //   status === "stopped");

    // Set the form is Valid flag 
    return {
      inputs: {
        ...state.inputs,
      },
      isFormValid: (state.inputs.description.isValid && 
                    state.inputs.serialNumber.isValid && 
                    state.inputs.model.isValid && state.inputs.variant.isValid &&
                    state.inputs.testCase.isValid && 
                    status === "stopped" && started === false)
    }

  }

  // Define the form state handler
  const [formState, dispatch] = useReducer(formReducer, {

    // Inputs to be monitored for the form state validated. 
    // Note: All the inputs should be valid for the form to become valid.
    inputs: {

      // Description
      description: {
        value: formData.description,
        isAttempted: false,
        isValid: false
      },

      // Serial Number
      serialNumber: {
        value: formData.serialNumber,
        isAttempted: false,
        isValid: false
      },

      // Model
      model: {
        value: formData.model,
        isAttempted: false,
        isValid: false
      },

      // Variant
      variant: {
        value: formData.variant,
        isAttempted: false,
        isValid: false
      },

      // Test Case
      testCase: {
        value: formData.testCase,
        isAttempted: false,
        isValid: false
      },

    },

    // Initial state of the form. Assume it is invalid.
    isFormValid: false

  } /*, initInitialState */);
  

  // Function to return the configuration based on the test cases selected
  function getLabMonConfig() {
          
    // Initialise the configuration data
    let labmonConfig = {
      "model": formData.model,
      "variant": formData.variant,
      "serial_number": formData.serialNumber,
      "description": formData.description,
      "requested_by": {
        "first_name": authorizationContext.firstName, 
        "last_name": authorizationContext.lastName, 
        "email": authorizationContext.emailAddress}
    }

    // Add-on if testcase option is Sample Qualification
    if (formData.testCase.indexOf("Sample Qualification") === 0) {

      // Update the configuration with the sample qualification details
      labmonConfig = {
          ...labmonConfig,
          "sample_qualification": {
            "required": true,
            "quick_test": true && (formData.testCase.indexOf("Sample Qualification (Quick)") === 0),
            "start_index" : sampleQualificationRange[0],
            "end_index" : sampleQualificationRange[1],
            "battery_monitor": true && (formData.testCase !== "Sample Qualification (Quick) (No Battery Monitor)")
          }
      } 

    }

    // Display the configuration message 
    console.log(JSON.stringify({"operation": "config", "config": labmonConfig}));

    // Return the labmon configuration 
    return JSON.stringify({"operation": "config", "config": labmonConfig});

  }
  
  // Function to handler form submission
  const onSubmitHandler = async (event) => {

    // Prevent the default handler 
    event.preventDefault();

    // Obtain the labmon configuration 
    const labmonConfig = getLabMonConfig();

    // Submit the labmon config request
    const sendLabMonConfig = async () => {

      // Format the back end URL
      const backendURL = 
        `${config.reliabilityServer.http_prefix}${config.reliabilityServer.hostname}:${config.reliabilityServer.port}/` +
        `labmon/${ip}/operation` ;

      try {

        // Create the POST request for labmon config operation
        const response = await fetch(backendURL , {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: labmonConfig
        });

        // Display the response data 
        console.log(response);

        // Check whether the operation was successful 
        if (response.status === 202) {

          // Debug message
          toast.success(`Labmon Configuration Successful for '${ip}'`)

          // Set the configured state 
          setConfigured(true);

        } else {

          // Error during labmon config operation
          toast.error(`Labmon Config Error for '${ip}'`)

          // Reset the configured state 
          setConfigured(false);

        }

      } catch (error) {

        // Debug message 
        console.log(error);
        toast.error(`Error During LabMon Config Operation '${ip}'`)

        // Reset the configured state 
        setConfigured(false);

      }
      
    }

    // Call the function to set the labmon configuration
    sendLabMonConfig();

  }

  // Function to format the 'sample qualification' test cases 'tool tip'
  const sampleQualificationTipFormater = (value) => {
    const testCases = [1, 2.1, 2.2, 2.3, 2.4, 3, 4, 5, 6, 7.1, 7.2, 8, 9, 10, 11, 12, 13, 14, 15, 16]
    return testCases[value - 1].toFixed(1)
  }

  // Start the labmon operation 
  useEffect(() => {

    // Submit the labmon start request
    const sendLabMonStart = async () => {

      // Format the back end URL
      const backendURL = 
        `${config.reliabilityServer.http_prefix}${config.reliabilityServer.hostname}:${config.reliabilityServer.port}/` +
        `labmon/${ip}/operation` ;

      try {

        // Create the POST request for labmon start operation
        const response = await fetch(backendURL , {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({operation: "start"})
        });

        // Display the response data 
        console.log(response);

        // Check whether the operation was successful 
        if (response.status === 202) {

          // Debug message
          toast.success(`Labmon Start Successful for '${ip}'`)

          // Set the started state 
          setStarted(true);

        } else {

          // Error during labmon start operation
          toast.error(`Labmon Start Error for '${ip}'`)

          // Reset the started state 
          setStarted(false);

        }

      } catch (error) {

        // Debug message 
        console.log(error);
        toast.error(`Error During LabMon Start Operation '${ip}'`)

        // Reset the started state 
        setStarted(false);

      }
      
    }

    // If labmon is configured and not started, start the operation 
    if ((configured === true) && (started === false)) {

      // Call the function to start the labmon operation
      sendLabMonStart();    

    }

  }, [configured, started]);


  // Return the form layout
  return (
    <Form onSubmit={onSubmitHandler}>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Description</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Description' : ''}
          value={formData.description}
          name="description"
          onChange={(event) => {
            dispatch({type: 'description', value: event.currentTarget.value})
            setFormData({...formData, description: event.currentTarget.value})
          }}
          type="string"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Serial Number</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Serial Number' : ''}
          value={formData.serialNumber}
          name="serialNumber"
          onChange={(event) => {
            dispatch({type: 'serialNumber', value: event.currentTarget.value.toUpperCase()})
            setFormData({...formData, serialNumber: event.currentTarget.value.toUpperCase()})
          }}
          type="string"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Model</Form.Label>}
        <Form.Select 
          onChange={(event) => {
            setSelectedModel(event.currentTarget.value)
            dispatch({type: 'model', value: event.currentTarget.value})
            setFormData({...formData, model: event.currentTarget.value})
          }}>
          <option key="Select the Model" value="">Select the Model</option>
          {models.map((model) => <option key={model.Model} value={model.Model}>{model.Model}</option>)}
        </Form.Select>
        {/*
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={(event) => {
            dispatch({type: 'email', value: event.currentTarget.value})
            setFormData({...formData, email: event.currentTarget.value})
          }}
          type="email"
        />
        */}
      </Form.Group>

      {(selectedModel !== '') && (<Form.Group className="mb-3">
        {hasLabel && <Form.Label>Variant</Form.Label>}
        <Form.Select
          onChange={(event) => {
            setSelectedVariant(event.currentTarget.value)
            dispatch({type: 'variant', value: event.currentTarget.value})
            setFormData({...formData, variant: event.currentTarget.value})
          }}>
          <option key="Select the Variant" value="Select the Variant">Select the Variant</option>
          {modelVariants.map((variant) => <option key={variant} value={variant}>{variant}</option>)}
        </Form.Select>
        {/*
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={(event) => {
            dispatch({type: 'password', value: event.currentTarget.value})
            setFormData({...formData, password: event.currentTarget.value})
          }}
          type="password"
        />
        */}
      </Form.Group>)}

      {(selectedModel !== '') && (selectedVariant !== '') && (<Form.Group className="mb-3">
        {hasLabel && <Form.Label>Test Case</Form.Label>}
        <Form.Select 
          onChange={(event) => {
            setSelectedTestCase(event.currentTarget.value)
            dispatch({type: 'testCase', value: event.currentTarget.value})
            setFormData({...formData, testCase: event.currentTarget.value})
          }}>
          <option key="Select the Test Case" value="Select the Test Case">Select the Test Case</option>
          {['Charging', 'Discharging', 'Sample Qualification', 'Sample Qualification (Quick)', 
            'Sample Qualification (Quick) (No Battery Monitor)'].map((testCase) => 
            <option key={testCase} value={testCase}>{testCase}</option>)}
        </Form.Select>
        {/*
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={(event) => {
            dispatch({type: 'password', value: event.currentTarget.value})
            setFormData({...formData, password: event.currentTarget.value})
          }}
          type="password"
        />
        */}
      </Form.Group>)}

      {(selectedTestCase.indexOf("Sample Qualification") === 0) && 
        (<Form.Group className="mb-3">
        {hasLabel && <Form.Label>Sample Qualification</Form.Label>}
        {/* Show this only if the test case selected is sample qualification */}
        <FalconReactRange
          // marks
          min={1}
          max={20}
          step={1} // 20 Sample Qualification Test Cases
          trackHeight=".3rem"
          variant="success"
          values={sampleQualificationRange}
          tipFormatter={val => sampleQualificationTipFormater(val)}
          onChange={val => setSampleQualificationRange(val)}
        />
      </Form.Group>)}

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formState.isFormValid}
        >
          Start
        </Button>
      </Form.Group>
    </Form>
  );
};

LabMonStartForm.propTypes = {
  layout: PropTypes.string,
  ip: PropTypes.string,
  locked_user: PropTypes.string,
  hasLabel: PropTypes.bool
};

LabMonStartForm.defaultProps = {
  layout: 'simple',
  ip: null,
  locked_user: null,
  hasLabel: false
};

export default LabMonStartForm;
