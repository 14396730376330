import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { useContext, useEffect } from 'react';
import AppContext from 'context/Context';

// Import the settings panel and settings toggle controls 
import SettingsPanel from 'components/settings-panel/SettingsPanel';
import SettingsToggle from 'components/settings-panel/SettingsToggle';

import is from 'is_js';
// import AppContext from 'context/Context';
import { CloseButton } from 'components/common/Toast';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';

// Import the authorization context 
import { AuthContext } from 'context/auth-context';

// Import the module for handling authroization
import { useAuth } from 'hooks/auth-hook';

/*
import VerticalNavbar from 'components/doc-components/VerticalNavbar';
import NavBarTop from 'components/doc-components/NavBarTop';
import NavbarDoubleTop from 'components/doc-components/NavbarDoubleTop';
*/

// Import the Login Page
import CardLogin from 'components/authentication/card/Login';

// Import the Resources Dashboard Page
import SupportDesk from 'components/dashboards/support-desk';
import ProjectManagement from 'components/dashboards/support-desk/projectManagement';

// Import the labmon start component 
import LabMonDashboard from 'components/labmon/Dashboard';
import LabMonStart from 'components/labmon/card/Start';
import Crm from 'components/dashboards/support-desk/LabMonStatus';
import ConfirmMail from 'components/authentication/card/ConfirmMail';
import ForgetPassword from 'components/authentication/card/ForgetPassword';
import LockScreen from 'components/authentication/card/LockScreen';
import Logout from 'components/authentication/card/Logout';
import PasswordReset from 'components/authentication/card/PasswordReset';
import Registration from 'components/authentication/card/Registration';
import ContactDetails from 'components/contact-details/ContactDetails';

const App = () => {
  /*
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const {
    config: { navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout');
    }
    return () => HTMLClassList.remove('double-top-nav-layout');
  }, [navbarPosition]);
  */

  // Register the useAuth() function and obtain the necessary parameters 
  const {isLoggedIn, firstName, lastName, emailAddress, jwtToken, permissions, login, logout} = useAuth();
  
  return (

    <AuthContext.Provider value={{isLoggedIn: isLoggedIn, 
      firstName: firstName, lastName: lastName, emailAddress: emailAddress,
      jwtToken: jwtToken, permissions: permissions, login: login, logout: logout}}>    
    <Router basename={process.env.PUBLIC_URL}>
      <Routes>
        {(!isLoggedIn) && <Route path="/" element={<CardLogin />} />}
        {(isLoggedIn) && <Route path="/" element={<SupportDesk />} />}
        {(isLoggedIn) && <Route path="/labMonDashboard" element={<LabMonDashboard />} />}
        {(isLoggedIn) && <Route path="/labMonStart" element={<LabMonStart />} />}
        {(isLoggedIn) && <Route path="/recentActivity" element={<ProjectManagement />} />}
        {(isLoggedIn) && <Route path="/status" element={<Crm />} />}
        {(isLoggedIn) && <Route path="/contact-details" element={<ContactDetails />} />}

        {(isLoggedIn) && <Route path="/ConfirmMail" element={<ConfirmMail />} />}
        {(isLoggedIn) && <Route path="/ForgotPassword" element={<ForgetPassword />} />}
        {(isLoggedIn) && <Route path="/LockScreen" element={<LockScreen />} />}
        {(isLoggedIn) && <Route path="/LogoutContent" element={<Logout />} />}
        {(isLoggedIn) && <Route path="/PasswordReset" element={<PasswordReset />} />}
        {(isLoggedIn) && <Route path="/Registration" element={<Registration />} />}


        {/* {(isLoggedIn) && <Route path="/setup" element={<SupportDesk />} />} */}
        {/*
        <Route
          path="components/navs-and-tabs/vertical-navbar"
          element={<VerticalNavbar />}
        />
        <Route
          path="components/navs-and-tabs/top-navbar"
          element={<NavBarTop />}
        />
        <Route
          path="components/navs-and-tabs/double-top-navbar"
          element={<NavbarDoubleTop />}
        />
        */}

      </Routes>
      {/*
      <SettingsToggle />
      <SettingsPanel />
      */}
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </Router>


    </AuthContext.Provider>    
  );
};

export default App;
