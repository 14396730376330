import React, { useEffect } from 'react';
import { useState } from 'react';

import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

// Import the device status component 
import DeviceStatus from 'components/labmon/DeviceStatus';

// Import the default configuration 
import config from 'config/default.json';


// Function to display the device contents (i.e. IP, test equipments, etc)
const DeviceContents = ({ip}) => {

  // Variable to hold the device status 
  const [status, setStatus] = useState({powerSupply: false, load: false, batMon: false, tempMon: false})

  // SSE message is updated in this function
  const updatefile = (data) => {

    // For Debug
    console.log("Connected device", data)

    // Update the device status 
    setStatus({powerSupply: data.PowerSupply, load: data.Load, batMon: data.BatMon, tempMon: data.TempMon})

  };  
  
  // Call the function to fetch the connected devices 
  useEffect(() => {

    // Function to fetch device connected from raspberry pi using Server-Side Events  
    const fetchDeviceConnected = () => {

      // Debug message 
      console.log(config);

      // Connecting to the SSE based on the ip selected
      const eventSource = new EventSource(
        `${config.reliabilityServer.http_prefix}${config.reliabilityServer.hostname}:${config.reliabilityServer.port}/sse/${ip}/events`);

      // SSE for to recieve message/data from raspberry pi
      eventSource.onmessage = (e) => updatefile(JSON.parse(e.data));

      return () => {
          eventSource.close();
      }

    };

    // Fetch the status of the connected devices
    fetchDeviceConnected();

  }, [ip]) // If the IP changes, fetch the device contents again

  return (
    <>
      <Row className="g-3 mb-3">
        <Col xxl={1} lg={1} />
        <Col xxl={10} lg={10}>
          <DeviceStatus ip={ip} status={status} />
        </Col>
        <Col xxl={1} lg={1} />
      </Row>
    </>
  );
};

DeviceContents.propTypes = {
  ip: PropTypes.string,
};

DeviceContents.defaultProps = {
  ip: '',
};

export default DeviceContents;
