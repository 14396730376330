// Import the create context module from react 
import { createContext } from "react";

// Create a new context 
export const AuthContext = createContext( {

  // Context variables
  isLoggedIn: false,
  firstName: '',
  lastName: '',
  emailAddress: '',
  jwtToken: '',
  permissions: {admin: false},

  // Function for updating the context on login and logout
  login: () => {},
  logout: () => {}
  
});
