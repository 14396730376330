// Import the react module 
import { useCallback, useEffect, useState } from 'react';


// Define the global timer variable for handling 'Auto Logout' after the expiry timer 
let autoLogoutTimer = null;

// Define the useAuth function
export const useAuth = () => {

  // Create the variables for maintaining the authorization context 
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [jwtToken, setJWTToken] = useState(null);
  const [permissions, setPermissions] = useState({})

  // Variable to hold the expiry time 
  const [expiryTime, setExpiryTime] = useState(null);

  // Define a function for hanlding login and logout events 
  const login  = useCallback((firstName, lastName, emailAddress, jwtToken, permissions, expiryTimeParam) => {

    // Debug message
    console.log('Login Invoked ...')

    // Set the user information
    setFirstName(firstName);
    setLastName(lastName);
    setEmailAddress(emailAddress);

    // Set the JWT token
    setJWTToken(jwtToken);

    // Set the permissions 
    setPermissions(permissions);

    // If the expiry time is specified, use it. Else compute it.
    // 1000 ms * 60 (seconds) * 60 (minutes) * 4 (hours)
    const expiryTime = expiryTimeParam || new Date(new Date().getTime() + 1000 * 60  * 60 * 4 );
    setExpiryTime(expiryTime);

    // Save the user information in the local storage
    localStorage.setItem('SEQRUserData', JSON.stringify({
      firstName: firstName, lastName: lastName, emailAddress: emailAddress, jwtToken: jwtToken,
      permissions: permissions, expiryTime: expiryTime.toISOString()
    }));

    // Set the flag to indicate the user is logged in
    setIsLoggedIn(true);

  }, [] /* No Dependencies */);

  const logout = useCallback(() => {

    // Debug message
    console.log('Logout Invoked ...')
  
    // Clear the user logged in flag
    setIsLoggedIn(false);

    // Delete the user information stored in local storage 
    localStorage.removeItem('SEQRUserData');

    // Clear the user information 
    setFirstName('');
    setLastName('');
    setEmailAddress('');

    // Clear the JWT token
    setJWTToken(null);

    // Clear the permissions 
    setPermissions({});

    // Clear the expiry time 
    setExpiryTime(null);

  }, [] /* No Dependencies */);

  // Register the useEffect() function to execute as soon as we login 
  useEffect(() => {

    // Attempt the extract the user information stored in local storage 
    const userData = localStorage.getItem('SEQRUserData');

    // If the user data is valid, auto login the user 
    if (userData) {

      // Parse the user data
      const userDataJSON = JSON.parse(userData);

      // Debug message 
      console.log(userDataJSON);

      // Call the login function with the user data 
      login(userDataJSON.firstName, userDataJSON.lastName, userDataJSON.emailAddress, userDataJSON.jwtToken, 
            userDataJSON.permissions, new Date(userDataJSON.expiryTime));

    }

  }, [login] /* 
              * No Dependencies, Hence runs once on first render. 
              * Note: Though we have mentinoed login as a dependency, login is a function pointer and does not change.
              */);

  // Define a useEffect() handler for updating the expiry timer 
  useEffect(() => {

    // If the token is valid, update the timeout 
    if (jwtToken && expiryTime) {
      const remainingTimeMS = expiryTime.getTime() - new Date().getTime();
      autoLogoutTimer = setTimeout(logout, remainingTimeMS);
    } else {
      clearTimeout(autoLogoutTimer);
    }

  }, [jwtToken, logout, expiryTime]);
  
  // Return the parameters required by App.js, used inside <AuthContext.Provider value=>
  return {isLoggedIn, firstName, lastName, emailAddress, jwtToken, permissions, login, logout};

}
