import holdTicket from 'assets/img/support-desk/hold-tickets.png';
import openTicket from 'assets/img/support-desk/open-tickets.png';
import dueTicket from 'assets/img/support-desk/due-tickets.png';
import unassigned from 'assets/img/support-desk/unassigned.png';

import sadashivan from 'assets/img/team/Sadashivan-thumb.jpg';
import labmon from 'assets/img/team/Labmon-thumb.png';

export const statusData = [
  {
    color: 'primary',
    img: holdTicket,
    percentage: '5.3%',
    count: '25',
    title: 'On Hold Tickets',
    dataArray: [10, 40, 30, 35, 20, 40],
    chartColor: '#2c7be5',
    icon: 'caret-up',
    className:
      'border-200 border-md-200 border-bottom border-md-end pb-x1 pe-md-x1'
  },
  {
    color: 'success',
    img: openTicket,
    percentage: '3.20%',
    count: '05',
    title: 'Open the ticket',
    dataArray: [10, 12, 16, 14, 20, 25],
    chartColor: '#00d27a',
    icon: 'caret-up',
    className: 'ps-md-x1 pb-x1 pt-x1 pt-md-0 border-bottom border-200'
  },
  {
    color: 'info',
    img: dueTicket,
    percentage: '2.3%',
    count: '02',
    title: 'Due Tickets Today',
    dataArray: [15, 10, 15, 10, 12, 10],
    chartColor: '#27bcfd',
    icon: 'caret-down',
    className:
      'border-md-200 border-200 border-bottom border-md-bottom-0 border-md-end pt-x1 pe-md-x1 pb-x1 pb-md-0'
  },
  {
    color: 'warning',
    img: unassigned,
    percentage: '3.12%',
    count: '03',
    title: 'Unassigned',
    dataArray: [10, 15, 12, 11, 14, 12],
    chartColor: '#f5803e',
    icon: 'caret-up',
    className: 'ps-md-x1 pt-x1'
  }
];

export const statusBadges = [
  { content: 'Completed Successfully',  type: 'success' },
  { content: 'Completed With Error',    type: 'danger' },
  { content: 'Running with Errors',     type: 'warning' },
  { content: 'Running',                 type: 'info' },
  { content: 'Free',                    type: 'secondary' }
];

export const priorityBadges = [
  { title: 'Urgent', color: '#e63757', data: 100 },
  { title: 'High',   color: '#F68F57', data: 75 },
  { title: 'Medium', color: '#2A7BE4', data: 50 },
  { title: 'Low',    color: '#00D27B', data: 25 },
]

export const setupInfo = [
  {
    id: 1,
    name: 'LabMon',
    setupip: '192.168.0.150',
    avatar: {
      img: labmon,
      size: 'xl',
      email: null
    },
    subject: 'No Tests Running. Setup is Powered Off / Not Reachable.',
    status: statusBadges[4],
    priority: priorityBadges[3],
    agent: 'Sadashivan',
    date: '2d ago'
  },
  {
    id: 2,
    name: 'LabMon',
    setupip: '192.168.0.151',
    avatar: {
      img: labmon,
      size: 'xl',
      email: null
    },
    subject: 'No Tests Running. Setup is Powered Off / Not Reachable.',
    status: statusBadges[4],
    priority: priorityBadges[3],
    agent: 'Sadashivan',
    date: '2d ago'
  },
  {
    id: 3,
    name: 'LabMon',
    setupip: '192.168.0.152',
    avatar: {
      img: labmon,
      size: 'xl',
      email: null
    },
    subject: 'No Tests Running. Setup is Powered Off / Not Reachable.',
    status: statusBadges[4],
    priority: priorityBadges[3],
    agent: 'Sadashivan',
    date: '2d ago'
  },
  {
    id: 4,
    name: 'LabMon',
    setupip: '192.168.0.153',
    avatar: {
      img: labmon,
      size: 'xl',
      email: null
    },
    subject: 'No Tests Running. Setup is Powered Off / Not Reachable.',
    status: statusBadges[4],
    priority: priorityBadges[3],
    agent: 'Sadashivan',
    date: '2d ago'
  },
  {
    id: 5,
    name: 'LabMon',
    setupip: '192.168.0.154',
    avatar: {
      img: labmon,
      size: 'xl',
      email: null
    },
    subject: 'No Tests Running. Setup is Powered Off / Not Reachable.',
    status: statusBadges[4],
    priority: priorityBadges[3],
    agent: 'Sadashivan',
    date: '2d ago'
  },
  {
    id: 6,
    name: 'LabMon',
    setupip: '192.168.0.155',
    avatar: {
      img: labmon,
      size: 'xl',
      email: null
    },
    subject: 'No Tests Running. Setup is Powered Off / Not Reachable.',
    status: statusBadges[4],
    priority: priorityBadges[3],
    agent: 'Sadashivan',
    date: '2d ago'
  },
  {
    id: 7,
    name: 'LabMon',
    setupip: '192.168.0.156',
    avatar: {
      img: labmon,
      size: 'xl',
      email: null
    },
    subject: 'No Tests Running. Setup is Powered Off / Not Reachable.',
    status: statusBadges[4],
    priority: priorityBadges[3],
    agent: 'Sadashivan',
    date: '2d ago'
  },
  {
    id: 8,
    name: 'LabMon',
    setupip: '192.168.0.157',
    avatar: {
      img: labmon,
      size: 'xl',
      email: null
    },
    subject: 'No Tests Running. Setup is Powered Off / Not Reachable.',
    status: statusBadges[4],
    priority: priorityBadges[3],
    agent: 'Sadashivan',
    date: '2d ago'
  },
  {
    id: 9,
    name: 'LabMon',
    setupip: '192.168.0.158',
    avatar: {
      img: labmon,
      size: 'xl',
      email: null
    },
    subject: 'No Tests Running. Setup is Powered Off / Not Reachable.',
    status: statusBadges[4],
    priority: priorityBadges[3],
    agent: 'Sadashivan',
    date: '2d ago'
  },
  {
    id: 10,
    name: 'LabMon',
    setupip: '192.168.0.159',
    avatar: {
      img: labmon,
      size: 'xl',
      email: null
    },
    subject: 'No Tests Running. Setup is Powered Off / Not Reachable.',
    status: statusBadges[4],
    priority: priorityBadges[3],
    agent: 'Sadashivan',
    date: '2d ago'
  },
  {
    id: 11,
    name: 'LabMon',
    setupip: '192.168.0.160',
    avatar: {
      img: labmon,
      size: 'xl',
      email: null
    },
    subject: 'No Tests Running. Setup is Powered Off / Not Reachable.',
    status: statusBadges[4],
    priority: priorityBadges[3],
    agent: 'Sadashivan',
    date: '2d ago'
  },
  {
    id: 12,
    name: 'LabMon',
    setupip: '192.168.0.161',
    avatar: {
      img: labmon,
      size: 'xl',
      email: null
    },
    subject: 'No Tests Running. Setup is Powered Off / Not Reachable.',
    status: statusBadges[4],
    priority: priorityBadges[3],
    agent: 'Sadashivan',
    date: '2d ago'
  },
  {
    id: 13,
    name: 'LabMon',
    setupip: '192.168.0.162',
    avatar: {
      img: labmon,
      size: 'xl',
      email: null
    },
    subject: 'No Tests Running. Setup is Powered Off / Not Reachable.',
    status: statusBadges[4],
    priority: priorityBadges[3],
    agent: 'Sadashivan',
    date: '2d ago'
  },
  {
    id: 14,
    name: 'LabMon',
    setupip: '192.168.0.163',
    avatar: {
      img: labmon,
      size: 'xl',
      email: null
    },
    subject: 'No Tests Running. Setup is Powered Off / Not Reachable.',
    status: statusBadges[4],
    priority: priorityBadges[3],
    agent: 'Sadashivan',
    date: '2d ago'
  },
  {
    id: 15,
    name: 'LabMon',
    setupip: '192.168.0.164',
    avatar: {
      img: labmon,
      size: 'xl',
      email: null
    },
    subject: 'No Tests Running. Setup is Powered Off / Not Reachable.',
    status: statusBadges[4],
    priority: priorityBadges[3],
    agent: 'Sadashivan',
    date: '2d ago'
  },
  {
    id: 16,
    name: 'LabMon',
    setupip: '192.168.0.165',
    avatar: {
      img: labmon,
      size: 'xl',
      email: null
    },
    subject: 'No Tests Running. Setup is Powered Off / Not Reachable.',
    status: statusBadges[4],
    priority: priorityBadges[3],
    agent: 'Sadashivan',
    date: '2d ago'
  },
  {
    id: 17,
    name: 'LabMon',
    setupip: '192.168.0.166',
    avatar: {
      img: labmon,
      size: 'xl',
      email: null
    },
    subject: 'No Tests Running. Setup is Powered Off / Not Reachable.',
    status: statusBadges[4],
    priority: priorityBadges[3],
    agent: 'Sadashivan',
    date: '2d ago'
  },
  {
    id: 100,
    name: 'Sadashivan M',
    setupip: '192.168.1.113',
    avatar: {
      img: sadashivan,
      size: 'xl',
      email: null
    },
    subject: '12V2A: 3Cell, 2600mAh, OCT2023 Manufacturing (Golden Sample)',
    status: statusBadges[0],
    priority: priorityBadges[0],
    agent: 'Sadashivan',
    date: '2d ago'
  }
];
