export const recentActivities = [
  {
    id: 0,
    title: 'Antony Hopkins sent an Email',
    text: 'Got an email for previous year sale report',
    icon: 'envelope',
    time: '2m ago',
    status: 'completed'
  },
  {
    id: 1,
    title: 'Emma archived a board',
    text: "A finished project's board is archived recently",
    icon: 'archive',
    time: '26m ago',
    status: 'completed'
  },
  {
    id: 2,
    title: 'Falcon v3.0.0 released with new features',
    text: 'Falcon new version is released successfully with new Dashboards',
    icon: 'code',
    time: '1h ago',
    status: 'current'
  },
  {
    id: 3,
    title: 'Rowan shared a link to the board',
    text: 'A link is shared with attachments',
    icon: 'code-branch',
    time: '3h ago',
    status: false
  },
  {
    id: 4,
    title: 'Anna updated a file',
    text: 'Fixed some bugs and spelling errors on this update',
    icon: ['far', 'file-code'],
    time: '4h ago',
    status: false
  }
];
