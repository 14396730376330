import React from 'react';

import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import DeviceDetailsTable from './DeviceDetailsTable';

// Import the assets files 
import labAssets from 'config/labAssets';

// Function to update the device status
const DeviceStatus = ({ip, status}) => {

  // Based on the IP address, fetch the device asset details 
  // Note: Dont deviceDetails as 'useState' variable. The 'status' parameter should be a state variable.
  //       If deviceDetails is a state variable, it is resuling in infinite 'rendering' loops.
  let deviceDetails = []
  labAssets.map((asset, index) => {
    if (asset.SetupIP === ip) {

      // Update the device connection status 
      asset.Devices[0] = {
        ...asset.Devices[0], 
        iconColor: ((asset.Devices[0].model.length > 0) && (status.powerSupply === true))?'text-success':'text-danger'
      };
      asset.Devices[1] = {
        ...asset.Devices[1], 
        iconColor: ((asset.Devices[1].model.length > 0) && (status.load === true))?'text-success':'text-danger'
      };
      asset.Devices[2] = {...asset.Devices[2], 
        iconColor: ((asset.Devices[2].model.length > 0) && (status.batMon === true))?'text-success':'text-danger'
      };
      asset.Devices[3] = {...asset.Devices[3], 
        iconColor: ((asset.Devices[3].model.length > 0) && (status.tempMon === true))?'text-success':'text-danger'
      };
      if (asset.Devices.length === 5) {
        asset.Devices[4] = {...asset.Devices[4], iconColor: 'text-secondary'};
      }

      // Update the device details 
      deviceDetails = asset.Devices

    }

    // Mandatory return for map() function
    return null;

  })


  // Display the IP address 
  // console.log(ip)

  // Display the device details
  // console.log(deviceDetails)
  
  return (
    <Card className="h-100">
      <Card.Body className="pt-0">
        <DeviceDetailsTable ip={ip} data={deviceDetails} />
      </Card.Body>
    </Card>
  );
};

DeviceStatus.propTypes = {
  ip: PropTypes.string.isRequired,
  status: PropTypes.object.isRequired,
};

export default DeviceStatus;
