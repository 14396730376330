import React from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  recentActivities,
} from 'data/dashboard/recentActivities';
import RecentActivity from './RecentActivity';

const ProjectManagement = () => {
  return (
    <>
      <Row className="g-3 mb-3">
        <Col xxl={{ span: 6, order: 1 }} lg={6}>
          <RecentActivity data={recentActivities} />
        </Col>
      </Row>
    </>
  );
};

export default ProjectManagement;
