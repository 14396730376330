import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DeviceDetailsTable = ({ip, data}) => {
  return (
    <>
      <Flex justifyContent="center" className="mt-3">
        <h6><b>{ip}</b></h6>
      </Flex>
      <Flex justifyContent="between" className="mt-3">
        <h6>Device & Status</h6>
        <h6>Asset Tag</h6>
      </Flex>
      {data.map((project, index) => {
        return (
          <Flex
            key={project.type}
            alignItems="center"
            justifyContent="between"
            className={classNames('rounded-3 bg-light p-3 ', {
              'mb-2': index !== data.length - 1
            })}
          >
            <>
              <Link to="#!">
                <h6 className="mb-0">
                  <FontAwesomeIcon
                    icon="circle"
                    className={`fs--1 me-3 ${project.iconColor}`}
                  />
                  {`${project.type}: ${project.model || "NA"}` }
                </h6>
              </Link>
              <Link className="fs--2 text-600 mb-0" to="#!">
                {project.assetTag || "NA"}
              </Link>
            </>
          </Flex>
        );
      })}
    </>
  );
};

DeviceDetailsTable.propTypes = {
  ip: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      model: PropTypes.string.isRequired,
      assetTag: PropTypes.string.isRequired,
      iconColor: PropTypes.string.isRequired
    })
  )
};

export default DeviceDetailsTable;
