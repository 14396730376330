import PropTypes from 'prop-types';
import React, {useState, useReducer, useContext } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

// Import the default.json file from the config folder
import config from 'config/default.json';

// Import the validators
import { validate, VALIDATOR_EMAIL, VALIDATOR_MINLENGTH } from 'utils/validators';

// Import the authorization context module 
import { AuthContext } from 'context/auth-context';


const LoginForm = ({ hasLabel, layout }) => {

  // Format data entered by the user and used for the login operation
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  });

  
  // Define the context variable and listen to context changes 
  const authorizationContext = useContext(AuthContext);

  // Form reducer is invoked when 'dispatch()' function is called.
  // In the form reducer function, we will validate the input and also check whether the form is valid.
  const formReducer = (state, action) => {

    // Check whether the user email is valid 
    if (action.type === 'email') {
      state.inputs.userEmail.value = action.value.trim();
      state.inputs.userEmail.isAttempted = true;
      state.inputs.userEmail.isValid = validate(action.value.trim(), [VALIDATOR_EMAIL()]);
    }

    // Check whether the password is valid 
    if (action.type === 'password') {
      state.inputs.password.value = action.value.trim();
      state.inputs.password.isAttempted = true;
      state.inputs.password.isValid = validate(action.value.trim(), [VALIDATOR_MINLENGTH(8)]);
    }
    
    // Set the form is Valid flag 
    return {
      inputs: {
        ...state.inputs,
      },
      isFormValid: (state.inputs.userEmail.isValid && state.inputs.password.isValid)
    }

  }

  // Define the form state handler
  const [formState, dispatch] = useReducer(formReducer, {

    // Inputs to be monitored for the form state validated. 
    // Note: All the inputs should be valid for the form to become valid.
    inputs: {

      // User Email
      userEmail: {
        value: formData.email,
        isAttempted: false,
        isValid: false
      },

      // Password
      password: {
        value: formData.password,
        isAttempted: false,
        isValid: false
      },

    },

    // Initial state of the form. Assume it is invalid.
    isFormValid: false

  } /*, initInitialState */);
  

  // Function to handler form submission
  const onSubmitHandler = async (event) => {

    // Prevent the default handler 
    event.preventDefault();

    // Format the back end URL
    let backendURL = `${config.authServer.http_prefix}${config.authServer.hostname}:${config.authServer.port}/login`;

    try {

      // Variable to hold the response 
      let response = null;

      // Create the POST request for login
      response = await fetch(backendURL , {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({userEmail: formData.email, password: formData.password})
      });

      // Display the response data 
      console.log(response);

      // Check whether the operation was successful 
      if (response.status === 200) {

        // Update the submit response data 
        const responseData = await response.json();

        // Display the response data 
        console.log(responseData);

        // Clear the login error message
        toast.success(`Logged in as '${responseData.firstName} ${responseData.lastName}'`)

        // Call the authentication context login function 
        // Note: We are passing 'null' for the expiry time. This will be computed in the App.js file.
        authorizationContext.login(
          responseData.firstName, responseData.lastName, responseData.emailAddress, 
          responseData.jwtToken, responseData.permissions, null);

      } else {

        // Set the login error message
        toast.error('Invalid Email / Password. Please check and re-enter your password.');

      }

    } catch (error) {

      // Debug message 
      console.log(error);
      toast.error('Error During Login. Try Again / Contact Administrator.')

    }
    
  }

  return (
    <Form onSubmit={onSubmitHandler}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={(event) => {
            dispatch({type: 'email', value: event.currentTarget.value})
            setFormData({...formData, email: event.currentTarget.value})
          }}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={(event) => {
            dispatch({type: 'password', value: event.currentTarget.value})
            setFormData({...formData, password: event.currentTarget.value})
          }}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={e =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })
              }
            />
            <Form.Check.Label className="mb-0 text-700">
              Remember me
            </Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs="auto">
          <Link
            className="fs--1 mb-0"
            to={`/authentication/${layout}/forgot-password`}
          >
            Forgot Password?
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formState.isFormValid}
        >
          Log in
        </Button>
      </Form.Group>
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
